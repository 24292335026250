import { IframeEmbedder } from "@/components"
import { Box } from "@mui/material"

export const AisicPresentation = () => (
    <Box
        width='100vw' height='100vh'
        sx={{ backgroundColor: 'black' }}
        display='flex' alignItems='center'
    >
        <IframeEmbedder
            src={"https://docs.google.com/presentation/d/1VK07EP23aeSo0bjSkjcHX5jM0Zwc92yklQkEFzIjeAU/embed?start=false&loop=false&delayms=3000"}
            variant={"googleSlides"}
            containerProps={{ width: '100%' }}
        />
    </Box>
)